<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_USER_GUIDE')" type="back" :settings="false" />
    </template>

    <div class="settings-container">
      <div class="container">
        <f7-list>
          <f7-list-item :title="$t.getTranslation('LBL_TERMS_AND_CONDITIONS')" :link="$configs?.externalPageLinks?.termsAndConditions" external target="_blank"></f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_PRIVACY_POLICY')" :link="$configs?.externalPageLinks?.privacyPolicy" external target="_blank"></f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_ORDER_AND_SHIPPING')" :link="$configs?.externalPageLinks?.orderAndShipping" external target="_blank"></f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_RETURN_POLICY')" :link="$configs?.externalPageLinks?.returnPolicy" external target="_blank"></f7-list-item>
        </f7-list>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, defineAsyncComponent } from "vue";

import { post, get } from "@/utils/axios";
import { logout } from "@/logics/auth.js";
import { useStore } from "@/store";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

export default defineComponent({
  name: "UserGuidePage",
  components: {
    ProfileNavigationComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const store = useStore();
   
    return {};
  },
});
</script>
